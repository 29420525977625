/**
 * Create URL query from object.
 * Replace false value with ""
 * @param {*} obj 
 * @returns 
 */
// export const buildURLQuery = function (obj) {
//   return Object.entries(obj)
//     .map(pair => pair.map(encodeURIComponent).join("="))
//     .join("&")
//   .replaceAll("undefined", "")
//   .replaceAll("false", "");
// }

export const buildURLQuery = function (obj) {
  // Create a shallow copy of the object to avoid modifying the original
  const queryParams = { ...obj };

  // Preprocess queryParams: if address_keyword is empty, null, or undefined, set it to address_key
  if (!queryParams.address_keyword || queryParams.address_keyword.trim() === '') {
    queryParams.address_keyword = queryParams.address_key || ''; // Default to empty string if address_key is also not provided
  }

  // Build the query string
  return Object.entries(queryParams)
    .map(pair => pair.map(encodeURIComponent).join("="))
    .join("&")
    .replaceAll("undefined", "")
    .replaceAll("false", "");
};


/**
 * Convert search query to object
 */
export const queryParser = function (queryStr) {
  let query = queryStr.substring(1)
  let vars = query.split("&")
  let queryObj = {}
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=")
    queryObj[pair[0]] = pair[1]
  }
  return queryObj
}



export const scrollToTop = () => {
  if (typeof window != "undefined") {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }
}